<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">About us</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">About us</span>
    </HeaderBasic>
    <!-- Start About Section  -->
    <section class="about section custom-about2">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="wow animate__animated animate__fadeInLeft">
              <h2 class="section-title">About us</h2>
              <p>
                Asamai Properties Services Ltd (Trading as ASAMAI PROPERTIES)
                have been established fairly new in the past couple of years,
                and we have been growing into a reputable big and great estate
                company offering both sales and letting management services in
                Leicester and its environs.
              </p>
              <p>
                At Asamai Properties, we pride ourselves in our outstanding
                <b>transparency and honesty</b> in the way we operate with our
                customers. Our team are experienced in the property market and
                will be more than happy to assist you with whatever queries you
                have about selling or LETTING A PROPERTY.
              </p>
              <p>
                We operate a high referral rate and that is evident of our proof
                of our QUALITY OF SERVICE AND HONESTY.
              </p>
              <p>
                We will look after the whole process for you so you don't have
                the stress of looking after your property.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="featured-thumb wow animate__animated animate__jackInTheBox"
            >
              <img src="/assets/img/about/img3.jpg" alt="about us image" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="featured-thumb wow animate__animated animate__fadeInLeft"
            >
              <img src="/assets/img/about/img2.jpg" alt="about us image" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="wow animate__animated animate__fadeInRight">
              <p>
                We have become a household name in our business operations in
                the local community and its surrounding. We are a name to be
                trusted for all your future needs in the property market.
              </p>
              <p>
                animate me: Asamai Properties have been established for a couple
                of years in the past and has maintained high momentum of growth
                pace. We are establishing and growing a new customised attitude
                towards estate agents.
              </p>
              <p>
                We are able to offer the most competitive fees for our clients
                simply for one reason, our business vision is to grow on long
                term relationships with our clients and not short term quick
                profits. We will endeavour to provide the highest service at the
                best value for money for our clients, after all, we are here
                because of their valued custom.
              </p>
              <p>
                We wanted to completely wipe out the notion that estate agents
                are known for their rogue and dishonesty and want to restore
                faith and transparency in the estate business in the local
                community and its environs by creating an alternative approach
                to doing this kind of business.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="wow animate__animated animate__fadeInLeft">
              <p>
                We pride ourselves in the transparency and the manner in which
                we do business from start to finish of our engagement.
              </p>
              <p>
                Whether you are a vendor, a landlord, a prospective purchaser or
                a prospective tenant, at Asamai Properties, we possess the
                expertise to assist you with selling, letting or purchasing
                property. Due to our customised nature of business relationships
                with our customers, we have gained over the years an exceptional
                understanding of the property market in and around Leicester, we
                are the choice for all your property needs.
              </p>
              <p>
                We have built and continue to build a very good portfolio of
                clients over the years, with properties in all areas of
                Leicester and in the Midlands, we consider our clients to be the
                best assets of our business. Our clientele growth is
                pre-dominantly by referrals; a proof that our business model is
                truly of value to our clients.
              </p>
              <p>
                At Asamai properties, we value family interactions as we are a
                family based company we realise the important issues in finding
                your next property, this is why we take an extra care assigned
                approach to making your next move an easy and less hassle one,
                with a dedicated agent to help you each step of the way through.
              </p>
              <p>
                At Asamai Properties, we are committed to delivering the highest
                level of quality customer service. We take pride in our
                exceptional service and ensuring we give you the best possible
                service that you can never obtain from of our competitors.
              </p>
              <p>
                At Asamai Properties, we have a team of people who are
                enthusiastic, knowledgeable, determined and willing to give
                their all to make sure you receive best possible outcome in any
                of your requirements.
              </p>
              <p>
                Why not come and see us for your next property venture, after
                all, if you don't try, you'll never know....
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="featured-thumb wow animate__animated animate__fadeInRight"
            >
              <img src="/assets/img/about/img1.jpg" alt="about us image" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Section  -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic")
  },
  mounted() {
    var wow = new WOW({
      live: false
    });
    wow.init();
  }
};
</script>
